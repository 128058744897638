/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
.bootstrap-iso {
    @import 'bootstrap/scss/bootstrap';
    @import "~bootstrap-icons/font/bootstrap-icons.css";
 }
 
.filled-btn {
    width: 150px;
    height: 30px;
    font: normal normal 600 14px/18px Readex Pro;
    letter-spacing: 0px;
    border: 1px solid #3d73d1;
    border-radius: 4px;
    background-color: #3d73d1;
    color: #fff;
    font-family: var(--bs-body-font-family);
}

body {
    font-family: 'Lato';
    font-style: normal;
    // min-height: 100vh;
    // width: 100%;
    // overflow-x: hidden;
    // position: relative;
}
.modal-50 .modal-content {
    margin-top: 25px !important;
}
.modal-content {
   margin-top: 200px;
  }
  .cdk-global-overlay-wrapper, .cdk-overlay-container {
    z-index: 99999 !important;
 }